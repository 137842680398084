import React from "react";
import { Link } from "gatsby";
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import favicon from '../images/favicon.ico';

// components
import Navbar from '../components/navbar/navbar';
import Footer from '../components/footer/footer';
import Article from '../components/article/article';
import ContactSection from "../components/contact/contact";
import { Fade } from 'react-reveal';

// icons
import icon_left from "../images/icons/icon-trygghet.svg";
import icon_center from "../images/icons/icon-professionalitet.svg";
import icon_right from "../images/icons/icon-langsiktighet.svg";

// images
import img_text_section from "../images/vector/vector-bokslut.svg";


function Bokslut() {
    return (
        <Layout>
            <Helmet>
                <title>Excendo Redovisning AB</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta property="og:title" content="Excendo Redovisning AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="" />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="http://www.excendo.se/" />
                <link rel="cannonical" href="http://www.excendo.se/" />
            </Helmet>

            <Navbar whitemode={true} />

            <section className="-mt-20">
                {/*-------------- hero section - START ---------------*/}
                <div className="w-screen bg-bokslut-hero bg-center bg-no-repeat bg-cover">
                    <div className="custome_overlay flex justify-center items-center py-48">
                        <h1 className="text-4xl font-semibold text-white text-center">Bokslut</h1>
                    </div>
                </div>
                {/*-------------- hero section - END ---------------*/}
            </section>



            <section>
                 {/*-------------- card section - START ---------------*/}
                <div className="container mx-auto -mt-12 px-4 xl:px-24">
                    <Fade top cascade>
                        <div className="w-full flex flex-col md:flex-row justify-center lg:justify-between items-center" >
                            <div className="w-full md:w-4/12 pb-4 md:pr-4">
                                <div className="w-full flex flex-col justify-center items-center bg-gray-50 md:bg-white rounded-lg py-4 md:py-8 shadow-md md:shadow-lg">
                                    <img className="object-center object-cover h-20 w-20" src={icon_left} alt="Trygghet" />
                                    <h2 className="text-lg mt-2 font-medium text-black-excendo">Trygghet</h2>
                                </div>
                            </div>
                            <div className="w-full md:w-4/12 pb-4 md:px-4">
                                <div className="w-full flex flex-col justify-center items-center bg-gray-50 md:bg-white rounded-lg py-4 md:py-8 shadow-md md:shadow-lg">
                                    <img className="object-center object-cover h-20 w-20 mx-auto" src={icon_center} alt="Professionalitet" />
                                    <h2 className="text-lg mt-2 font-medium text-black-excendo">Professionalitet</h2>
                                </div>
                            </div>
                            <div className="w-full md:w-4/12 pb-4 md:pl-4">
                                <div className="w-full flex flex-col justify-center items-center bg-gray-50 md:bg-white rounded-lg py-4 md:py-8 shadow-md md:shadow-lg">
                                    <img className="object-center object-cover h-20 w-20 mx-auto" src={icon_right} alt="Långsiktighet" />
                                    <h2 className="text-lg mt-2 font-medium text-black-excendo">Långsiktighet</h2>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
                {/*-------------- card section - END ---------------*/}
            </section>



            <section>
                {/*-------------- text section - START ---------------*/}
                <Article
                    image={img_text_section}
                    image_alt="professionell hjälp med bokslut"
                    heading="Bokslut"
                    title="Professionell hjälp med bokslut"
                >
                    <p>
                        Hos oss får du professionell hjälp med bokslut. Det är många aspekter att ta hänsyn till för 
                        att säkerställa att bokslutet blir korrekt eftersom det är bokslutet som ligger till 
                        grund för företagets beskattning. När företagets verksamhetsår är slut ska all din 
                        ekonomiska historik avslutas i ett bokslut och vi ser till att alla dina siffror 
                        hamnar rätt i slutändan. Vi är måna om att ha en personlig relation med dig som kund. 
                        Allt för att du ska känna dig trygg!
                    </p>
                </Article>
                {/*-------------- text section - END ---------------*/}
            </section>



            <div className="container mx-auto px-4 xl:px-24">
                <hr className="border-1 border-lightgray-excendo"></hr>
            </div>



            <section className="my-32">
                {/*-------------- other services - START ---------------*/}
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-20">
                        <div className="px-8 md:px-0">
                            <div className="bg-blue-100 rounded-full w-16 h-16 flex justify-center items-center text-blue-excendo shadow-2xl">
                                <svg className="h-8 w-8" viewBox="0 0 20 20" fill="currentColor" >
                                    <path d="M18.1116+15.2486L14.5763+15.2486L14.5763+11.7134C16.9335+11.7134+18.1116+12.8915+18.1116+15.2486Z" opacity="1" fill="currentColor"/>
                                    <path stroke="currentColor" stroke-width="2" d="M14.5763+11.7134C12.6239+11.7134+11.0411+13.2962+11.0411+15.2486C11.0411+17.2011+12.6239+18.7839+14.5763+18.7839C16.5288+18.7839+18.1116+17.2011+18.1116+15.2486C18.1116+13.2962+16.5288+11.7134+14.5763+11.7134Z" fill="none" stroke-linecap="butt" opacity="1" stroke-linejoin="miter"/>
                                    <path d="M8.38971+15.2486C8.38971+11.8318+11.1596+9.062+14.5763+9.062C14.8768+9.062+15.1711+9.09117+15.4602+9.13271L15.4602+6.25857C15.4602+5.79016+15.2737+5.3403+14.9422+5.00887L10.6752+0.741863C10.3438+0.410436+9.89395+0.223953+9.42553+0.223953L3.08688+0.223953C2.1147+0.223953+1.31927+1.01938+1.31927+1.99156L1.31927+16.1324C1.31927+17.1046+2.1147+17.9001+3.08688+17.9001L8.99335+17.9001C8.61066+17.0958+8.38971+16.1987+8.38971+15.2486ZM9.27352+1.54966L14.1344+6.41059L9.27352+6.41059L9.27352+1.54966Z" opacity="1" fill="currentColor"/>
                                </svg>
                            </div>
                            <h2 className="uppercase mt-6 text-blue-excendo font-medium mb-3">Årsredovisning</h2>
                            <p className="font-light text-sm text-gray-500 mb-3">
                            Kraven på hur årsredovisningen ska göras skiljer sig lite åt beroende på storleken på ert företag.
                            </p>
                            <Link to="/arsredovisning" className="text-blue-excendo flex items-center hover:text-blue-600">
                                Läs mer
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"  fill="currentColor">
                                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                </svg>
                            </Link>
                        </div>

                        <div className="px-8 md:px-0">
                            <div className="bg-red-100 rounded-full w-16 h-16 flex justify-center items-center text-orange-excendo shadow-2xl">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" viewBox="0 0 20 20" fill="currentColor" >
                                    <path stroke="currentColor" stroke-width="2" d="M18.8285+7.74489C18.4+8.7946+18.8285+9.7342+18.8285+9.7342L11.5453+18.5046L2.61791+15.8547C2.61791+15.8547+1.13001+15.2201+1.13001+13.297C1.13001+10.6366+3.42211+11.2407+3.42211+11.2407" fill="none" stroke-linecap="round" opacity="1" stroke-linejoin="round"/>
                                    <path d="M19.6097+4.04747C19.5211+3.79007+19.3031+3.59887+19.0383+3.54085L9.78137+1.47342C9.50313+1.41539+9.21225+1.50987+9.02478+1.72636L1.3279+10.2936C1.15159+10.4953+0.665048+11.2742+0.514027+12.2859C0.943284+11.754+1.45586+11.4884+1.71476+11.5576L10.7812+14.1458C10.8482+14.1644+10.9166+14.1726+10.9828+14.1726C11.2142+14.1726+11.4374+14.0699+11.5869+13.8869L19.4765+4.7996C19.6491+4.58907+19.6997+4.30414+19.6097+4.04747ZM15.3721+6.26369L14.7792+6.96598C14.614+7.16089+14.2324+7.2576+13.9274+7.18544L8.40135+5.8389C8.09708+5.76599+7.98251+5.54727+8.14469+5.35533L8.7391+4.64933C8.90277+4.4559+9.28516+4.35919+9.59018+4.43209L15.1162+5.77641C15.419+5.84931+15.5351+6.06878+15.3721+6.26369Z" opacity="1" fill="currentColor"/>
                                </svg>
                            </div>
                            <h2 className="uppercase mt-6 text-orange-excendo font-medium mb-3">Bokföring</h2>
                            <p className="font-light text-sm text-gray-500 mb-3">
                                Alla företag som bedriver någon form av näringsverksamhet är bokföringsskyldiga.
                            </p>
                            <Link to="/bokforing" className="text-orange-excendo flex items-center hover:text-red-600">
                                Läs mer
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"  fill="currentColor">
                                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                </svg>
                            </Link>
                        </div>

                        
                        <div className="px-8 md:px-0">
                            <div className="bg-blue-100 rounded-full w-16 h-16 flex justify-center items-center text-blue-excendo shadow-2xl">
                                <svg className="h-8 w-8" viewBox="0 0 20 20" fill="currentColor" >
                                    <path d="M11.9856+0.187482L4.31846+0.187482C3.26423+0.187482+2.40168+1.05003+2.40168+2.10426L2.40168+17.4385C2.40168+18.4927+3.26423+19.3553+4.31846+19.3553L10.9601+19.3553L8.64079+17.0456C7.89325+16.2884+7.89325+15.0809+8.64079+14.3333L12.7139+10.2602C13.0686+9.90555+13.5573+9.70429+14.0653+9.70429C14.5732+9.70429+15.062+9.90555+15.4262+10.2602L17.7359+12.5795L17.7359+5.93782L11.9856+0.187482ZM11.0272+6.89621L11.0272+1.62507L16.2983+6.89621L11.0272+6.89621Z" opacity="1" fill="currentColor"/>
                                    <path stroke="currentColor" stroke-width="2" d="M17.2519+18.8713L13.8238+15.4431" fill="none" stroke-linecap="round" opacity="1" stroke-linejoin="round"/>
                                    <path d="M14.4055+11.9584L16.4383+13.9912C16.6251+14.1781+16.6251+14.4819+16.4383+14.6687L13.0494+18.0576C12.8625+18.2445+12.5587+18.2445+12.3718+18.0576L10.3391+16.0249C10.1522+15.838+10.1522+15.5342+10.3391+15.3473L13.7279+11.9584C13.9148+11.7715+14.2186+11.7715+14.4055+11.9584Z" opacity="1" fill="currentColor"/>
                                </svg>
                            </div>
                            <h2 className="uppercase mt-6 text-blue-excendo font-medium mb-3">Deklarationer</h2>
                            <p className="font-light text-sm text-gray-500 mb-3">
                                Vi gör det enklare för dig som företagare att deklarera vare sig det handlar om inkomstdeklarationen eller momsdeklarationen.
                            </p>
                            <Link to="/deklarationer" className="text-blue-excendo flex items-center hover:text-blue-600">
                                Läs mer
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"  fill="currentColor">
                                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                </svg>
                            </Link>
                        </div>

                        
                        <div className="px-8 md:px-0">
                            <div className="bg-red-100 rounded-full w-16 h-16 flex justify-center items-center text-orange-excendo shadow-2xl">
                                <svg className="h-8 w-8" viewBox="0 0 20 20" fill="currentColor" >
                                    <path d="M5.76263+10.3926C5.76263+9.8791+5.34662+9.46403+4.83405+9.46403C4.32147+9.46403+3.90547+9.8791+3.90547+10.3926C3.90547+10.9061+4.32147+11.3212+4.83405+11.3212C5.34662+11.3212+5.76263+10.9061+5.76263+10.3926ZM15.0484+10.3926C15.0484+9.8791+14.6324+9.46403+14.1198+9.46403C13.6073+9.46403+13.1913+9.8791+13.1913+10.3926C13.1913+10.9061+13.6073+11.3212+14.1198+11.3212C14.6324+11.3212+15.0484+10.9061+15.0484+10.3926Z" opacity="1" fill="currentColor"/>
                                    <path d="M18.7627+10.3926L18.7627+5.74971C18.7627+5.23713+18.3467+4.82113+17.8342+4.82113L1.11973+4.82113C0.607153+4.82113+0.191149+5.23713+0.191149+5.74971L0.191149+15.0355C0.191149+15.5481+0.607153+15.9641+1.11973+15.9641L17.017+15.9641L17.017+14.1069L2.04831+14.1069L2.04831+6.67829L16.9056+6.67829L16.9056+10.3926C16.9056+10.9052+17.3216+11.3212+17.8342+11.3212L17.8342+11.3212C18.3467+11.3212+18.7627+10.9052+18.7627+10.3926Z" opacity="1" fill="currentColor"/>
                                    <path d="M1.58402+13.1783C0.814758+13.1783+0.191149+13.802+0.191149+14.5712C0.191149+15.3405+0.814758+15.9641+1.58402+15.9641C2.35328+15.9641+2.97689+15.3405+2.97689+14.5712C2.97689+13.802+2.35328+13.1783+1.58402+13.1783ZM1.58402+4.82113C0.814758+4.82113+0.191149+5.44474+0.191149+6.214C0.191149+6.98326+0.814758+7.60687+1.58402+7.60687C2.35328+7.60687+2.97689+6.98326+2.97689+6.214C2.97689+5.44474+2.35328+4.82113+1.58402+4.82113ZM17.3699+4.82113C16.6006+4.82113+15.977+5.44474+15.977+6.214C15.977+6.98326+16.6006+7.60687+17.3699+7.60687C18.1391+7.60687+18.7627+6.98326+18.7627+6.214C18.7627+5.44474+18.1391+4.82113+17.3699+4.82113ZM9.47695+8.07116C8.19484+8.07116+7.1555+9.11051+7.1555+10.3926C7.1555+11.6747+8.19484+12.7141+9.47695+12.7141C10.759+12.7141+11.7984+11.6747+11.7984+10.3926C11.7984+9.11051+10.759+8.07116+9.47695+8.07116Z" opacity="1" fill="currentColor"/>
                                    <path d="M2.97689+14.1069L2.97689+16.8927C2.97689+18.4313+4.22397+19.6784+5.76263+19.6784L14.1458+19.6784C14.6176+19.6784+15.0716+19.4992+15.4161+19.176L19.4805+15.3661C20.0581+14.8247+20.1018+13.9212+19.578+13.3269L19.578+13.3269C19.1657+12.8589+18.5037+12.7029+17.9261+12.9378L15.0484+14.1069L2.97689+14.1069ZM13.1913+0.178231L9.47695+0.178231L4.32426+1.65096C3.52661+1.87846+2.97689+2.6074+2.97689+3.43662L2.97689+6.67829L15.0484+6.67829L15.0484+2.03539C15.0484+1.00931+14.2173+0.178231+13.1913+0.178231Z" opacity="1" fill="currentColor"/>
                                </svg>
                            </div>
                            <h2 className="uppercase mt-6 text-orange-excendo font-medium mb-3">Löneadministration</h2>
                            <p className="font-light text-sm text-gray-500 mb-3">
                            Excendo hjälper ert företag med att ha full kontroll på alla löneutbetalningar.
                            </p>
                            <Link to="/loneadministration" className="text-orange-excendo flex items-center hover:text-red-600">
                                Läs mer
                                <svg className="h-5 w-5" viewBox="0 0 20 20"  fill="currentColor">
                                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                </svg>
                            </Link>
                        </div>

                        
                        <div className="px-8 md:px-0">
                            <div className="bg-blue-100 rounded-full w-16 h-16 flex justify-center items-center text-blue-excendo shadow-2xl">
                                <svg className="h-8 w-8" viewBox="0 0 20 20" fill="currentColor" >
                                    <path d="M19.7158+1.27309L0.353428+1.27309L0.353428+18.6993L19.7158+18.6993L19.7158+1.27309ZM3.25779+5.14557L11.0028+5.14557L11.0028+7.08182L3.25779+7.08182L3.25779+5.14557ZM3.25779+9.01806L11.0028+9.01806L11.0028+10.9543L3.25779+10.9543L3.25779+9.01806ZM16.8115+14.8268L11.0028+14.8268L11.0028+12.8905L16.8115+12.8905L16.8115+14.8268ZM16.8115+10.9543L12.939+10.9543L12.939+9.01806L16.8115+9.01806L16.8115+10.9543ZM16.8115+7.08182L12.939+7.08182L12.939+5.14557L16.8115+5.14557L16.8115+7.08182Z" opacity="1" fill="currentColor"/>
                                </svg>
                            </div>
                            <h2 className="uppercase mt-6 text-blue-excendo font-medium mb-3">Fakturering</h2>
                            <p className="font-light text-sm text-gray-500 mb-3">
                                Fakturering är en viktig del i varje företags administration och det kan vara både svårt och tidskrävande.
                            </p>
                            <Link to="/fakturering" className="text-blue-excendo flex items-center hover:text-blue-600">
                                Läs mer
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"  fill="currentColor">
                                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                </svg>
                            </Link>
                        </div>
                    </div> 
                </div> 
                {/*-------------- other services - END ---------------*/}
            </section>



            <ContactSection />
            <Footer />
        </Layout>
    );
}

export default Bokslut;